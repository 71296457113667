import type { ApolloError } from '@apollo/client';

/**
 * @deprecated use the one exported from `'@anchorage/sentry';`
 */
const isUnauthorizedError = (error: ApolloError | undefined) => {
  if (!error) {
    return false;
  }

  const { networkError } = error;
  if (
    networkError &&
    'statusCode' in networkError &&
    networkError.statusCode === 401
  ) {
    return true;
  }

  return false;
};

export default isUnauthorizedError;
