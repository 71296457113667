const PortoSignUpIllustration = (props: SVGProps): JSX.Element => {
  const { title = 'PortoSignUp', ...restProps } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 264 123"
      {...restProps}
      fill="none"
      stroke="none"
    >
      <title>{title}</title>
      <path
        fill="#F4F5F5"
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="0.496"
        d="M1.005 55.001s0-4.433 4.736-3.624 6.243 1.105 6.342-1.106 2.013-4.433 6.446-3.124 9.064 3.124 10.274 2.112c1.21-1.011 2.014-1.106 1.106-4.23-.907-3.123 4.13-4.834 7.954-3.123 3.828 1.71 15.709 8.965 20.945 12.991 0 0-57.808-.506-57.808.1z"
      />
      <path
        stroke="#fff"
        fill="none"
        strokeMiterlimit="10"
        strokeWidth="0.655"
        d="M92.016 32.012C91.074 14.726 76.763 1 59.244 1S27.409 14.726 26.472 32.012h-.025v.54a36 36 0 0 0-.024 1.274c0 .427.01.848.024 1.275v55.473h65.619V32.022h-.05z"
      />
      <path
        stroke="#fff"
        fill="none"
        strokeMiterlimit="10"
        strokeWidth="0.655"
        d="M56.861 112.52v-7.819c-.06-2.604-2.201-4.711-4.844-4.711h-8.232c-2.643 0-4.785-2.108-4.785-4.71 0-2.604 2.142-4.712 4.785-4.712h90.01"
      />
      <path
        fill="inherit"
        stroke="none"
        d="M123.756 30.93c11.667 0 21.128-9.773 21.128-21.828h-42.257c0 12.055 9.461 21.828 21.129 21.828"
      />
      <path
        stroke="#fff"
        fill="none"
        strokeMiterlimit="10"
        strokeWidth="0.655"
        d="M26.551 64.233 52.501 90.5"
      />
      <path
        fill="inherit"
        stroke="inherit"
        strokeMiterlimit="10"
        strokeWidth="0.655"
        d="M23.924 38.285a8.157 8.157 0 1 0 0-16.314 8.157 8.157 0 0 0 0 16.314Z"
      />
      <path
        fill="none"
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="0.655"
        d="M56.5 112.303h125.555c3.55 0 6.431-2.499 6.431-6 0-3.496-2.881-6.332-6.431-6.332h-16.791"
      />
      <path
        fill="#fff"
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="0.942"
        d="M247.751 107.175c8.511-8.51 8.288-22.536-.497-31.321l-30.825 30.824c8.786 8.786 22.811 9.008 31.322.497Z"
      />
      <path
        fill="none"
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="0.793"
        d="M166 40.009h54.655a4.783 4.783 0 0 1 4.78 4.78 4.783 4.783 0 0 1-4.78 4.78h-8.222a4.783 4.783 0 0 0-4.78 4.78v7.23M235.809 54.815c4.15 0 3.922 4.419 3.922 4.419v6.396h-19.463"
      />
      <path
        fill="inherit"
        stroke="none"
        d="M200.567 59.235v42.049h32.331v-42.05c0-4.001 3.392-4.418 3.392-4.418h-31.304c-4.22 0-4.414 4.419-4.414 4.419z"
      />
      <path
        stroke="inherit"
        fill="none"
        strokeMiterlimit="10"
        strokeWidth="0.496"
        d="M200.567 59.235v42.049h32.331v-42.05c0-4.001 3.392-4.418 3.392-4.418h-31.304c-4.22 0-4.414 4.419-4.414 4.419z"
      />
      <path
        stroke="none"
        fill="#fff"
        d="M203.2 93.667c-.282.134-.515.496-.515.809v1.378c0 .313-.258.57-.571.57h-2.846a.574.574 0 0 1-.57-.57v-1.378c0-.313-.233-.68-.516-.814l-.778-.446c-.258-.178-.69-.198-.957-.044l-1.195.689a.57.57 0 0 1-.779-.209l-1.423-2.464a.57.57 0 0 1 .208-.778l1.195-.69c.273-.158.471-.54.442-.853 0 0-.075-.768.049-1.462 0 0-.223-.134-.491-.288l-1.19-.684a.57.57 0 0 1-.208-.779l1.423-2.464a.57.57 0 0 1 .779-.209l1.19.69c.272.158.704.139.962-.04l.778-.446c.283-.134.516-.496.516-.809v-1.378c0-.312.258-.57.57-.57h2.846c.313 0 .571.258.571.57v1.378c0 .313.233.68.515.814l.779.446c.258.178.689.198.957.045l1.195-.69a.57.57 0 0 1 .778.209l1.424 2.464a.57.57 0 0 1-.209.779l-1.195.689c-.273.158-.471.54-.441.853 0 0 .074.768-.05 1.463 0 0 .223.134.491.287l1.19.684a.57.57 0 0 1 .209.779l-1.424 2.464a.57.57 0 0 1-.778.209l-1.19-.69c-.273-.158-.704-.138-.962.04z"
      />
      <path
        fill="none"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="0.793"
        d="M200.691 91.41a2.985 2.985 0 1 0 0-5.969 2.985 2.985 0 0 0 0 5.97Z"
      />
      <path
        stroke="inherit"
        fill="none"
        strokeMiterlimit="10"
        strokeWidth="0.793"
        d="M222.787 72.717h3.967"
      />
      <path
        fill="none"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="0.793"
        d="M206.904 72.716h14.028M206.904 68.615h8.747M206.904 76.817h10.409M206.904 81.22h14.182M214.075 85.322h13.993M214.075 89.422h13.993M228.004 93.524H214.01v3.614h13.994z"
      />
      <path
        fill="#222325"
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="0.793"
        d="M201.881 47.848a7.939 7.939 0 1 0 0-15.877 7.94 7.94 0 0 0 0 15.877Z"
      />
      <path
        fill="none"
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="0.793"
        d="m198.013 40.004 2.762 2.762 4.969-6.104"
      />
      <path
        fill="none"
        stroke="#fff"
        strokeMiterlimit="10"
        strokeWidth="0.65"
        d="M123.204 100.611 69 65.951l54.204-34.65 54.205 34.65z"
      />
      <circle cx="97.895" cy="92.187" r="20.01" fill="#fff" stroke="none" />
      <circle cx="149.194" cy="92.187" r="20.01" fill="#fff" stroke="none" />
      <path
        fill="none"
        stroke="#000"
        strokeLinejoin="round"
        strokeWidth="1.107"
        d="M149.585 96.747v-16.82l-7.549 12.365 7.555 4.455z"
      />
      <path
        fill="none"
        stroke="#000"
        strokeLinejoin="round"
        strokeWidth="1.107"
        d="M149.585 96.747v-16.82l7.555 12.365z"
      />
      <path
        fill="none"
        stroke="#000"
        strokeLinejoin="round"
        strokeWidth="1.107"
        d="m142.036 92.294 7.555-3.207 7.555 3.207M149.585 99.349v5.852l-9.327-11.052zM149.585 99.349v5.852l9.327-11.052z"
      />
      <path
        fill="#000"
        stroke="none"
        d="m100.643 92.607 2.671 7.301 2.282-1.312-3.584-9.747zm-1.871 5.11 1.454 3.97 2.282-1.313-2.35-6.423z"
      />
      <path
        fill="#000"
        stroke="none"
        d="M97.578 104.016a1.36 1.36 0 0 0 1.369 0l8.716-5.032a1.372 1.372 0 0 0 .685-1.187v-10.07a1.37 1.37 0 0 0-.685-1.186l-8.716-5.038a1.37 1.37 0 0 0-1.37 0l-8.716 5.032a1.37 1.37 0 0 0-.684 1.186v10.065a1.37 1.37 0 0 0 .684 1.186l1.616.931.477-1.305-1.408-.812V87.728l8.716-5.033 8.717 5.033v10.064l-8.717 5.032-1.945-1.123-.478 1.305"
      />
      <path
        fill="#000"
        stroke="none"
        fillRule="evenodd"
        d="m92.751 101.224-2.276-1.312 4.628-12.642h2.756zm6.092-13.954h2.755l-5.759 15.737-2.276-1.314z"
        clipRule="evenodd"
      />
      <circle cx="97.895" cy="45.98" r="20.01" fill="#fff" stroke="none" />
      <g fill="#000" clipPath="url(#clip0_1144_67139)">
        <path d="m99.524 42.567-7.206 5.343v5.274l-7.286-7.253 7.286-7.282v3.918zM110.759 45.902l-7.288-7.253v5.274l-7.204 5.343h7.204v3.918z" />
      </g>
      <circle cx="148.205" cy="45.98" r="20.01" fill="#fff" stroke="none" />
      <path
        fill="#000"
        stroke="none"
        d="M160.168 40.458a1.4 1.4 0 0 0-.186-.769 1.46 1.46 0 0 0-.575-.558l-4.764-2.665a1.69 1.69 0 0 0-1.609 0l-4.745 2.654a1.66 1.66 0 0 0-.572.576c-.139.235-.216.5-.223.771l.044 9.73a1.2 1.2 0 0 1-.17.582 1.26 1.26 0 0 1-.432.437l-2.519 1.408a1.25 1.25 0 0 1-1.218 0l-2.535-1.417a1.2 1.2 0 0 1-.447-.433 1.2 1.2 0 0 1-.169-.591l-.042-2.775c.006-.205.064-.405.169-.583s.253-.328.431-.437l2.523-1.41a1.25 1.25 0 0 1 1.218 0l1.915 1.07v-2.934l-1.771-.99a1.69 1.69 0 0 0-1.61 0l-4.743 2.654a1.66 1.66 0 0 0-.572.577c-.139.235-.216.5-.224.771l.022 5.323c.003.275.08.543.222.78s.345.434.589.572l4.815 2.692c.242.135.517.206.796.206.284-.001.563-.07.813-.203l4.743-2.654c.237-.143.433-.341.572-.577a1.6 1.6 0 0 0 .223-.772l-.041-3.69.045-2.475-.07-3.642c.007-.204.065-.403.169-.58.105-.178.252-.328.429-.438l2.576-1.383a1.27 1.27 0 0 1 1.218 0l2.535 1.418c.185.104.339.253.447.432.108.18.166.383.169.591l.015 2.827a1.2 1.2 0 0 1-.17.58 1.27 1.27 0 0 1-.43.436l-2.576 1.386a1.26 1.26 0 0 1-1.218 0l-1.965-1.098v2.934l1.819 1.018a1.69 1.69 0 0 0 1.608 0l4.716-2.612c.237-.143.434-.34.573-.576s.216-.5.224-.772z"
      />
      <defs>
        <clipPath id="clip0_1144_67139">
          <path
            fill="#fff"
            stroke="none"
            d="M85.032 38.649h25.727v14.536H85.032z"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export { PortoSignUpIllustration };
