import cn from 'classnames';

const VaultIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Vault', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.625 12C8.625 10.136 10.136 8.625 12 8.625C13.6062 8.625 14.9503 9.74701 15.2914 11.25H17.25V12.75H15.2914C14.9503 14.253 13.6062 15.375 12 15.375C10.136 15.375 8.625 13.864 8.625 12ZM12 10.125C10.9645 10.125 10.125 10.9645 10.125 12C10.125 13.0355 10.9645 13.875 12 13.875C13.0355 13.875 13.875 13.0355 13.875 12C13.875 10.9645 13.0355 10.125 12 10.125Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 5.25C3 4.00736 4.00736 3 5.25 3H18.75C19.9926 3 21 4.00736 21 5.25V18.75C21 19.9926 19.9926 21 18.75 21H18V22.5H16.5V21H7.5V22.5H6V21H5.25C4.00736 21 3 19.9926 3 18.75V17.7993C2.55165 17.54 2.25 17.0552 2.25 16.5V15.75C2.25 15.1948 2.55165 14.71 3 14.4507V9.54933C2.55165 9.28997 2.25 8.80521 2.25 8.25V7.5C2.25 6.94479 2.55165 6.46003 3 6.20067V5.25ZM4.5 14.4507V9.54933C4.94835 9.28997 5.25 8.80521 5.25 8.25V7.5C5.25 6.94479 4.94835 6.46003 4.5 6.20067V5.25C4.5 4.83579 4.83579 4.5 5.25 4.5H18.75C19.1642 4.5 19.5 4.83579 19.5 5.25V18.75C19.5 19.1642 19.1642 19.5 18.75 19.5H5.25C4.83579 19.5 4.5 19.1642 4.5 18.75V17.7993C4.94835 17.54 5.25 17.0552 5.25 16.5V15.75C5.25 15.1948 4.94835 14.71 4.5 14.4507Z"
      />
    </svg>
  );
};

export { VaultIcon };
