// This import needs to happen before any component.
// It contains tailwind's normalize wich will override
// component's behaviour if imported after them.
import 'styles/globals.scss';
import { Toaster } from '@latitude/toast';
import NextAdapterPages from 'next-query-params/pages';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import { Inter, Roboto_Mono } from 'next/font/google';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import { QueryParamProvider } from 'use-query-params';
import { NonceProvider } from '@anchorage/common/dist/components/SelectV2';
import { reportError } from '@anchorage/common/dist/utils/errors';
import FadingTransition from '@anchorage/frontoffice/components/FadingTransition';
import useIsPortoApp from '@anchorage/frontoffice/utils/useIsPortoApp';
import usePortoTheme from '@anchorage/frontoffice/utils/usePortoTheme';
import { LocalStorageProvider } from '@anchorage/hooks';
import ApolloClientProvider from 'components/ApolloClientProvider';
import { ToasterAndTooltipProvider } from '@latitude/utils';
import initDataDogRUM from 'utils/datadog';
import { CSP } from './_document';
export const PAGES_WITHOUT_LOGIN = ['/login', '/verify-email'];

// https://nextjs.org/docs/app/api-reference/components/font#css-variables
// Levering next/font to avoid CSP issues when downloading google fonts
const inter = Inter({
  subsets: ['latin'],
  display: 'swap',
  preload: true,
  adjustFontFallback: false
});
const roboto = Roboto_Mono({
  subsets: ['latin'],
  display: 'swap',
  preload: true,
  adjustFontFallback: false
});
const AppShellWithoutLogin = dynamic(async () => {
  const {
    AppShellWithoutLogin
  } = await import('components/AppShell/AppShellWithoutLogin');
  return AppShellWithoutLogin;
}, {
  ssr: false
});
const AppShellWithLogin = dynamic(async () => {
  const {
    AppShellWithLogin
  } = await import('components/AppShell/AppShellWithLogin');
  return AppShellWithLogin;
}, {
  ssr: false
});
function MyApp({
  Component,
  pageProps
}: AppProps) {
  // Use the layout defined at the page level, if available
  // @ts-ignore TODO Luis use types from source/js/anchorage/apps/backoffice/src/types.ts
  const getLayout = Component.getLayout || (page => page);
  const isPorto = useIsPortoApp();
  usePortoTheme({
    active: isPorto
  });
  const appWindow = typeof window !== 'undefined' ? window : null;
  const appWindowEnv = appWindow && appWindow.env ? appWindow.env : null;
  const [skipSSG, setSkipSSG] = useState(true);
  const [dataDogInitialized, setDataDogInitialized] = useState(false);
  const {
    basePath,
    pathname
  } = useRouter();
  const AppShell = PAGES_WITHOUT_LOGIN.some(route => pathname.includes(route)) ? AppShellWithoutLogin : AppShellWithLogin;
  useEffect(() => {
    if (dataDogInitialized || !appWindowEnv) {
      return;
    }
    const datadogApplicationId = appWindowEnv.DATADOG_APPLICATION_ID;
    const datadogClientToken = appWindowEnv.DATADOG_CLIENT_TOKEN;
    const datadogSite = appWindowEnv.DATADOG_SITE;
    const datadogService = appWindowEnv.DATADOG_SERVICE;
    const datadogEnvironment = appWindowEnv.DATADOG_ENVIRONMENT;

    // For now, only initialize Datadog RUM in DEVELOPMENT
    if (appWindowEnv.DATADOG_ENVIRONMENT === 'development') {
      initDataDogRUM({
        applicationId: datadogApplicationId || '',
        clientToken: datadogClientToken || '',
        site: datadogSite,
        service: datadogService,
        env: datadogEnvironment
      });
    }
    setDataDogInitialized(true);
  }, [dataDogInitialized, appWindowEnv]);
  useEffect(() => {
    setSkipSSG(false);
  }, [skipSSG]);
  const faviconPath = useMemo(() => {
    return `${basePath}/${isPorto ? 'portoFavicon.ico' : 'favicon.ico'}`;
  }, [isPorto, basePath]);

  //* Set the CSS variables for font families
  // Using useEffect ensures styles are set dynamically after mount, allows reactivity,
  // prevents SSR issues, provides control over timing, and improves performance.
  useEffect(() => {
    document.documentElement.style.setProperty('--font-inter', inter.style.fontFamily);
    document.documentElement.style.setProperty('--font-roboto-mono', roboto.style.fontFamily);
  }, []);
  return <>
      <NonceProvider nonce={CSP.nonce} cacheKey="anchor-client-dash" data-sentry-element="NonceProvider" data-sentry-source-file="_app.tsx">
        <LocalStorageProvider errorHandler={reportError} data-sentry-element="LocalStorageProvider" data-sentry-source-file="_app.tsx">
          <Head data-sentry-element="Head" data-sentry-source-file="_app.tsx">
            <link id="favicon" rel="shortcut icon" href={faviconPath} />
          </Head>
          <ApolloClientProvider data-sentry-element="ApolloClientProvider" data-sentry-source-file="_app.tsx">
            <ToasterAndTooltipProvider tooltipProps={{
            delayDuration: 300,
            skipDelayDuration: 500
          }} data-sentry-element="ToasterAndTooltipProvider" data-sentry-source-file="_app.tsx">
              <QueryParamProvider adapter={NextAdapterPages} options={{
              // ! experimental feature !
              // allows multiple consecutive calls to setQueryParams in a row only result in a single update to the URL).
              enableBatching: true
            }} data-sentry-element="QueryParamProvider" data-sentry-source-file="_app.tsx">
                <AppShell data-sentry-element="AppShell" data-sentry-source-file="_app.tsx">
                  <FadingTransition data-sentry-element="FadingTransition" data-sentry-source-file="_app.tsx">
                    {!skipSSG && <>
                        {getLayout(<Component {...pageProps} />)}
                        <Toaster />
                      </>}
                  </FadingTransition>
                </AppShell>
              </QueryParamProvider>
            </ToasterAndTooltipProvider>
          </ApolloClientProvider>
        </LocalStorageProvider>
      </NonceProvider>
    </>;
}
export default MyApp;