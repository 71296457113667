import { cva } from 'class-variance-authority';

export const toastVariants = cva(
  [
    'data-[state=open]:animate-in',
    'data-[state=closed]:animate-out',
    'data-[swipe=end]:animate-out',
    'data-[state=closed]:fade-out-80',
    'data-[state=closed]:slide-out-to-left-full',
    'data-[state=open]:slide-in-from-bottom-full',
    'group',
    'pointer-events-auto',
    'relative',
    'flex',
    'w-[30rem]',
    'items-center',
    'justify-between',
    'overflow-hidden',
    'rounded-radius-comp-toast',
    'w-sizing-comp-toast-min-width',
    'gap-spacing-comp-toast-gap',
    'py-spacing-comp-toast-vertical',
    'px-spacing-comp-toast-horizontal',
    'shadow-box-shadow-sys-medium',
    'transition-all',
    'data-[swipe=cancel]:translate-x-0',
    'data-[swipe=end]:translate-x-[var(--radix-toast-swipe-end-x)]',
    'data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)]',
    'data-[swipe=move]:transition-none',
  ],
  {
    variants: {
      variant: {
        info: [
          'bg-color-comp-toast-container-info',
          'text-color-comp-toast-on-container-info',
        ],
        success: [
          'bg-color-comp-toast-container-success',
          'text-color-comp-toast-on-container-success',
        ],
        warning: [
          'bg-color-comp-toast-container-warning',
          'text-color-comp-toast-on-container-warning',
        ],
        danger: [
          'bg-color-comp-toast-container-danger',
          'text-color-comp-toast-on-container-danger',
        ],
        alert: [
          // TODO: this token is wrong, design is working on a FIX, in the meantime we need to use `bg-color-ref-ui-surface-container`
          // 'bg-color-comp-toast-container-alert',
          'bg-color-ref-ui-surface-container',
          'text-color-comp-toast-on-container-alert',
        ],
        loading: [
          'bg-color-ref-ui-surface-container',
          'text-color-comp-toast-on-container-alert',
        ],
      },
    },
    defaultVariants: {
      variant: 'info',
    },
  },
);
