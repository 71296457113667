import cn from 'classnames';

const EmptyRulesIllustration = (props: SVGProps): JSX.Element => {
  const { title = 'Empty Rules', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="92"
      height="60"
      viewBox="0 0 92 60"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <g id="Clip path group">
        <mask
          id="mask0_4793_76254"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="92"
          height="60"
        >
          <g id="clip0_3631_101270">
            <path
              id="Vector"
              d="M91.468 0H0.532227V60H91.468V0Z"
              fill="white"
            />
          </g>
        </mask>
        <g mask="url(#mask0_4793_76254)">
          <g id="Group">
            <path
              id="Vector_2"
              d="M91.2365 0.228516H69.6021V28.6189H91.2365V0.228516Z"
              fill="#5580F6"
              stroke="#5580F6"
              strokeWidth="2.5"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_3"
              d="M72.4482 6.04492H76.7818"
              stroke="black"
              strokeWidth="2.5"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_4"
              d="M77.7402 13.8809H87.5035"
              stroke="white"
              strokeWidth="2.5"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_5"
              d="M77.7402 15.7031H87.5035"
              stroke="white"
              strokeWidth="2.5"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_6"
              d="M77.7402 17.5234H87.5035"
              stroke="white"
              strokeWidth="2.5"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_7"
              d="M77.7402 19.3457H87.5035"
              stroke="white"
              strokeWidth="2.5"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_8"
              d="M77.7402 21.168H87.5035"
              stroke="white"
              strokeWidth="2.5"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_9"
              d="M77.7402 22.9902H87.5035"
              stroke="white"
              strokeWidth="2.5"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_10"
              d="M77.7402 24.8125H87.5035"
              stroke="white"
              strokeWidth="2.5"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_11"
              d="M83.853 6.73027L85.0851 7.96041L87.5035 5.5918"
              stroke="black"
              strokeWidth="2.5"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_12"
              d="M72.4482 7.50586H74.615"
              stroke="black"
              strokeWidth="2.5"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_13"
              d="M17.6018 8.23229H40.3895V6.4375H17.6018V8.23229Z"
              stroke="white"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_14"
              d="M17.6018 12.0917H31.9428V10.2969H17.6018V12.0917Z"
              stroke="white"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_15"
              d="M17.6018 16.1424H31.9428V14.3477H17.6018V16.1424Z"
              stroke="white"
              strokeWidth="2"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_16"
              d="M17.6018 1.80469H39.7009"
              stroke="#5580F6"
              strokeWidth="2.5"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_17"
              d="M22.2327 3.46878C23.0672 3.46878 23.7439 2.79327 23.7439 1.95998C23.7439 1.12669 23.0672 0.451172 22.2327 0.451172C21.3981 0.451172 20.7214 1.12669 20.7214 1.95998C20.7214 2.79327 21.3981 3.46878 22.2327 3.46878Z"
              fill="white"
              stroke="white"
              strokeWidth="2.5"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_18"
              d="M50.263 57.0644C44.6514 54.8645 39.5265 51.7351 35.8357 48.25C31.3608 44.0242 28.9939 39.3053 28.9939 34.6029V12.2441L50.9442 2.68164L72.777 12.2074V34.6011C72.777 39.3035 70.4119 44.0224 65.9352 48.2481C62.2444 51.7332 57.1195 54.8645 51.5079 57.0626L50.8855 57.3064L50.2611 57.0626L50.263 57.0644Z"
              fill="white"
            />
            <path
              id="Vector_19"
              d="M50.8872 26.1162C53.7512 26.1162 56.0727 23.7981 56.0727 20.9389C56.0727 18.0796 53.7512 15.7617 50.8872 15.7617C48.0232 15.7617 45.7017 18.0796 45.7017 20.9389C45.7017 23.7981 48.0232 26.1162 50.8872 26.1162Z"
              fill="black"
              stroke="black"
              strokeWidth="2.5"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_20"
              d="M50.8872 44.2291V24.4863"
              stroke="black"
              strokeWidth="8"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_21"
              d="M54.3245 38.0625H50.7935"
              stroke="black"
              strokeWidth="8"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_22"
              d="M54.3245 41.1719H50.7935"
              stroke="black"
              strokeWidth="8"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_23"
              d="M31.7334 32.9668H34.9303C36.6508 32.9668 38.0464 34.3601 38.0464 36.0779V40.3733"
              stroke="#5580F6"
              strokeWidth="2.5"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_24"
              d="M17.613 32.9668H14.4161C12.6956 32.9668 11.3 34.3601 11.3 36.0779V40.3733"
              stroke="#5580F6"
              strokeWidth="2.5"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_25"
              d="M17.613 59.7698H14.4161C12.6956 59.7698 11.3 58.3765 11.3 56.6587V52.3633"
              stroke="#5580F6"
              strokeWidth="2.5"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_26"
              d="M31.7334 59.7698H34.9303C36.6508 59.7698 38.0464 58.3765 38.0464 56.6587V52.3633"
              stroke="#5580F6"
              strokeWidth="2.5"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_27"
              d="M24.6732 56.1423C30.0795 56.1423 34.4622 51.7666 34.4622 46.369C34.4622 40.9712 30.0795 36.5957 24.6732 36.5957C19.267 36.5957 14.8843 40.9712 14.8843 46.369C14.8843 51.7666 19.267 56.1423 24.6732 56.1423Z"
              fill="#5580F6"
              stroke="#5580F6"
              strokeWidth="2.5"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_28"
              d="M20.5012 44.2012V46.2086"
              stroke="black"
              strokeWidth="2.5"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_29"
              d="M28.8633 44.2012V46.2086"
              stroke="black"
              strokeWidth="2.5"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_30"
              d="M24.9909 44.207V48.0038C24.9909 48.4071 24.6641 48.7334 24.2601 48.7334H23.2336"
              stroke="black"
              strokeWidth="2.5"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_31"
              d="M21.5698 51.2263C21.5698 51.2263 22.4641 52.6123 24.7153 52.6123C26.9665 52.6123 27.8295 51.043 27.8295 51.043"
              stroke="black"
              strokeWidth="2.5"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_32"
              d="M20.6096 20.63V23.4844L15.1541 20.63H0.532227V10.1875H22.2952V20.575L20.6096 20.63Z"
              fill="white"
            />
            <path
              id="Vector_33"
              d="M17.949 12.9082V17.6528"
              stroke="black"
              strokeWidth="2.5"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_34"
              d="M19.8218 16.3417L16.0759 14.2188"
              stroke="black"
              strokeWidth="2.5"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_35"
              d="M16.0759 16.3417L19.8218 14.2188"
              stroke="black"
              strokeWidth="2.5"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_36"
              d="M11.4136 12.9082V17.6528"
              stroke="black"
              strokeWidth="2.5"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_37"
              d="M13.2867 16.3417L9.54077 14.2188"
              stroke="black"
              strokeWidth="2.5"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_38"
              d="M9.54077 16.3417L13.2867 14.2188"
              stroke="black"
              strokeWidth="2.5"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_39"
              d="M4.87866 12.9082V17.6528"
              stroke="black"
              strokeWidth="2.5"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_40"
              d="M6.75153 16.3417L3.00562 14.2188"
              stroke="black"
              strokeWidth="2.5"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_41"
              d="M3.00562 16.3417L6.75153 14.2188"
              stroke="black"
              strokeWidth="2.5"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_42"
              d="M86.4366 39.1191H59.1245V46.9455H86.4366V39.1191Z"
              stroke="white"
              strokeWidth="2.5"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_43"
              d="M81.6497 43.0332H83.5042"
              stroke="white"
              strokeWidth="2.5"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_44"
              d="M81.6497 43.0332H83.5042"
              stroke="white"
              strokeWidth="2.5"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_45"
              d="M78.3757 43.0332H80.234"
              stroke="white"
              strokeWidth="2.5"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_46"
              d="M78.3757 43.0332H80.234"
              stroke="white"
              strokeWidth="2.5"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_47"
              d="M75.1052 43.0332H76.9617"
              stroke="white"
              strokeWidth="2.5"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_48"
              d="M75.1052 43.0332H76.9617"
              stroke="white"
              strokeWidth="2.5"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_49"
              d="M86.4366 49.9805H59.1245V57.8068H86.4366V49.9805Z"
              stroke="white"
              strokeWidth="2.5"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_50"
              d="M81.6497 53.8926H83.5042"
              stroke="white"
              strokeWidth="2.5"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_51"
              d="M81.6497 53.8926H83.5042"
              stroke="white"
              strokeWidth="2.5"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_52"
              d="M78.3757 53.8926H80.234"
              stroke="white"
              strokeWidth="2.5"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_53"
              d="M78.3757 53.8926H80.234"
              stroke="white"
              strokeWidth="2.5"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_54"
              d="M75.1052 53.8926H76.9617"
              stroke="white"
              strokeWidth="2.5"
              strokeMiterlimit="10"
            />
            <path
              id="Vector_55"
              d="M75.1052 53.8926H76.9617"
              stroke="white"
              strokeWidth="2.5"
              strokeMiterlimit="10"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export { EmptyRulesIllustration };
