import { Tooltip } from '@latitude/tooltip';
import { Component, ReactNode } from 'react';

export class SafeTooltip extends Component<
  { text: string; children: ReactNode },
  { hasError: boolean }
> {
  constructor(props: { text: string; children: ReactNode }) {
    super(props);
    this.state = { hasError: false };
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static getDerivedStateFromError(_: any) {
    return { hasError: true };
  }
  render() {
    if (this.state.hasError) {
      return this.props.children;
    }
    return <Tooltip text={this.props.text}>{this.props.children}</Tooltip>;
  }
}
