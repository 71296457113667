/* eslint-disable react/button-has-type */
import classnames from 'classnames';
import React from 'react';

import styles from './index.css';

import Loader from '../Loader';

export type ButtonTypes =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'destructive'
  | 'success';

export type Props = {
  children?: React.ReactNode;
  className?: string;
  Icon?: React.ElementType;
  iconClassName?: string;
  isActive?: boolean;
  isLoading?: boolean;
  type?: ButtonTypes;
  disabled?: boolean;
  htmlType?: 'button' | 'submit' | 'reset';
  [key: string]: any;
};

/**
 *
 * @deprecated
 * use "@latitude/button" instead - [storybook example](https://devdebug.anchorage-development.com/latitude-storybook/?path=/docs/actions-button--documentation)
 * */
function Button(
  {
    children,
    className = '',
    Icon,
    iconClassName = '',
    isActive = false,
    isLoading = false,
    type = 'primary',
    disabled,
    htmlType = 'button',
    ...restProps
  }: Props,
): JSX.Element {
  const classes = classnames({
    [styles.button]: true,
    [styles[`button-${type}`]]: true,
    [styles.active]: isActive,
    // has icon and children
    [styles.hasIcon]: Boolean(Icon) && Boolean(children),
    // has icon but no children
    [styles.iconOnly]: !children && (isLoading || Boolean(Icon)),
    [className]: Boolean(className),
    [styles.loading]: isLoading,
  });

  const genericIconClasses = classnames({
    [styles.icon]: true,
    // Put a margin to the right if there are children
    [styles.margin]: Boolean(children),
    [iconClassName]: Boolean(iconClassName),
  });

  const loadingIconClasses = classnames({
    [styles.icon]: true,
    [styles[type]]: true,
  });

  return (
    <button
      className={classes}
      type={htmlType}
      disabled={disabled || isLoading}
      {...restProps}
    >
      {isLoading && (
        <div className={styles.loadingWrapper}>
          <Loader className={loadingIconClasses} isCentered={false} />
        </div>
      )}

      {Icon && <Icon className={genericIconClasses} />}
      {children}
    </button>
  );
}

export default Button;
