import * as Sentry from '@sentry/browser';
import { GraphQLError } from 'graphql';

// Types
import { ErrorOptions } from './_types';

import ClientGraphQLError from './ClientGraphQLError';
import FatalError from './FatalError';
import UIDeveloperError from './UIDeveloperError';
import { ClientError } from './WrappedError';
// Helper Functions
import { STATUSES_TO_IGNORE, printError, shouldThrowError } from './_helpers';

/**
 * setErrorScope adds custom key value pairs to the scope of the error. This
 * allows us to send extra data to Sentry for debugging
 * @param extraKeyValuePairs
 */
const setErrorScope = (extraKeyValuePairs: { [key: string]: any }): void => {
  if (!extraKeyValuePairs) {
    return;
  }

  Sentry.getCurrentScope().setExtras(extraKeyValuePairs);
};

/**
 * setLevel configures the scope of the reported exception to use a level other
 * than the default 'error' level. 'fatal' will page the on-call engineer.
 * @param level severity of the captured exception
 */
const setLevel = (level: Sentry.SeverityLevel): void => {
  Sentry.getCurrentScope().setLevel(level);
};

/**
 * @deprecated use the one exported from `'@anchorage/sentry';`
 *
 * reportError reports an error to our error capturing service. It contains
 * logic to ignore client input errors, backend errors, or errors that are
 * otherwise unactionable
 * @param error
 * @param opts
 */
export default function reportError(
  error: ClientError,
  opts?: ErrorOptions,
): void {
  if (!error) {
    return;
  }

  let errorToReport = error;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore Apollo gives GraphQLErrors which are plain objects by the time
  // they reach this function
  if (!(error instanceof Error) && error.message && error.path) {
    // Typecast to a GraphQL Error
    const gqlError = error as GraphQLError;
    const errorCode = gqlError.extensions && gqlError.extensions.errorCode;
    if (STATUSES_TO_IGNORE.indexOf(errorCode) !== -1) {
      // Do not report an error from the frontend since these errors are already
      // captured in the backend
      return;
    }

    if (errorCode === 400) {
      // Log this as an Info level event so that we are aware that the client
      // made an invalid request, but we do not page the oncall engineer.
      const infoSeverityLevel: Sentry.SeverityLevel = 'info';
      setLevel(infoSeverityLevel);
    }

    // Create a GraphQL error from the plain object given to us by Apollo
    // See graphql/graphql-js/src/error/GraphQLError.js for this error type
    errorToReport = new ClientGraphQLError(gqlError);
  }

  // Do not report ServerParseErrors, which are when the server sends back
  // HTML instead of valid JSON for reasons like the client is logged out, the
  // load balancer returns a 502, or the backend returns a 408. Don't report
  // these errors because they are not actionable
  if (error.name === 'ServerParseError') {
    return;
  }

  // These will call the on-call engineer
  if (errorToReport instanceof FatalError) {
    const fatalSeverityLevel: Sentry.SeverityLevel = 'fatal';
    setLevel(fatalSeverityLevel);
  }

  // Create warnings for UIDeveloperErrors. These should be known but not
  // page the on-call engineer
  if (errorToReport instanceof UIDeveloperError) {
    const warningSeverityLevel: Sentry.SeverityLevel = 'warning';
    setLevel(warningSeverityLevel);
  }

  setErrorScope(errorToReport);

  const extra: ErrorOptions = {};

  if (opts && opts.orgID) {
    extra.orgID = opts.orgID;
  }

  const hint = Object.keys(extra).length ? { extra } : undefined;

  if (errorToReport instanceof Error) {
    // Add each custom field to the error's scope so that the reports have
    // access to the custom fields
    if (hint) {
      Sentry.captureException(errorToReport, hint);
    } else {
      Sentry.captureException(errorToReport);
    }
  } else {
    // Call "captureEvent" instead of "captureException" so that Sentry does not
    // capture it as "Non-Error exception captured"
    Sentry.captureEvent(errorToReport);
  }

  // Log the error
  printError(error);

  if (shouldThrowError(error, opts)) {
    throw error;
  }
}
