// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/
import * as Sentry from '@sentry/nextjs';

import {
  SENTRY_IGNORE_ERRORS,
  shouldSendErrorToSentry,
} from '../errors/helpers';

type InitOptions = Partial<Parameters<typeof Sentry.init>[0]>;

const beforeSend: InitOptions['beforeSend'] = (event, hint) => {
  const err = hint?.originalException ?? null;

  if (!shouldSendErrorToSentry(err as string)) {
    return null;
  }

  return event;
};

export const sentryInit = ({
  dsn,
  environment,
  release,
  debug,
  ...sentryInitConfig
}: InitOptions = {}) => {
  const DSN = dsn ?? window.env?.SENTRY_DSN;
  const RELEASE = release ?? window.env?.SENTRY_RELEASE;
  const ENVIRONMENT = environment ?? window.env?.SENTRY_ENVIRONMENT;
  const DEBUG = debug ?? window.env?.SENTRY_DEBUG;

  // Do not initialize the Sentry instance with a DSN if you don't want to report
  // errors for that environment
  if (process.env.NODE_ENV === 'production') {
    if (!DSN) {
      console.error('no sentry dsn defined');
      return;
    }
    if (!RELEASE) {
      console.error('no sentry release defined');
      return;
    }
    if (!ENVIRONMENT) {
      console.error('no sentry environment defined');
      return;
    }
    Sentry.init({
      dsn: DSN,
      environment: ENVIRONMENT,
      release: RELEASE,
      // Add optional integrations for additional features
      integrations: [
        // do not use, it's added cost
        // Sentry.replayIntegration(),
        // This implements Sentry's own UI
        // Use https://docs.sentry.io/platforms/javascript/user-feedback/configuration/#bring-your-own-widget
        // Sentry.feedbackIntegration({
        //   // Additional SDK configuration goes in here, for example:
        //   colorScheme: 'system',
        // }),
      ],
      // Define how likely traces are sampled. Adjust this value in production, or use tracesSampler for greater control.
      tracesSampleRate: 1,
      // Define how likely Replay events are sampled.
      // This sets the sample rate to be 10%. You may want this to be 100% while
      // in development and sample at a lower rate in production
      replaysSessionSampleRate: 0.1,
      // Define how likely Replay events are sampled when an error occurs.
      replaysOnErrorSampleRate: 1.0,
      // Setting this option to true will print useful information to the console while you're setting up Sentry.
      debug: !!DEBUG,
      // ignore errors that are not actionable
      ignoreErrors: SENTRY_IGNORE_ERRORS,
      // ignore errors that are not actionable
      beforeSend,
      // overrides
      ...sentryInitConfig,
    });
  }
};
