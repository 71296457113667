const formatMessage = (message: string, error?: Error): string => {
  if (error) {
    return `${message}: ${error.message}`;
  }

  return message;
};
export default class FatalError extends Error {
  cause: Error | null;

  constructor(message: string = 'FatalError', error?: Error) {
    // Format the message and add the error if present
    super(formatMessage(message, error));

    // Set the name of this error for display purposes
    this.name = message;

    // Keep a reference to the original error that caused this
    this.cause = error || null;

    // captureStackTrace may not exist (IE)
    if (
      Object.prototype.hasOwnProperty.call(Error, 'captureStackTrace') &&
      !!Error.captureStackTrace
    ) {
      // Pass this.constructor to get rid of this constructor
      // in the stack trace
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(formatMessage(message, error)).stack;
    }
  }
}
