import * as ToastPrimitives from '@radix-ui/react-toast';
import { type VariantProps } from 'class-variance-authority';
import cn from 'classnames';
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';
import { toastVariants } from 'variants/toast-variants';

/**
 * Represents a toast component.
 *
 * @component
 * @example
 * ```tsx
 * <Toast variant="success" />
 * ```
 */
const Toast = forwardRef<
  ElementRef<typeof ToastPrimitives.Root>,
  ComponentPropsWithoutRef<typeof ToastPrimitives.Root> &
    VariantProps<typeof toastVariants> & {
      'data-testid'?: string;
    }
>(({ className, variant, ...props }, ref) => {
  return (
    <ToastPrimitives.Root
      ref={ref}
      data-testid={props['data-testid'] || 'toast'}
      className={cn(toastVariants({ variant }), className)}
      {...props}
    />
  );
});
Toast.displayName = ToastPrimitives.Root.displayName;

export { Toast };
