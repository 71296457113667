import { cva } from 'class-variance-authority';

export const actionVariants = cva([], {
  variants: {
    fill: {
      outline: '',
      ghost: '',
    },
  },
  defaultVariants: {
    fill: 'outline',
  },
});
