// eslint-disable-next-line @next/next/no-document-import-in-page
import { Head, Html, Main, NextScript } from 'next/document';
import Script from 'next/script';
import { REACT_SELECT_NONCE } from '@anchorage/frontoffice/constants/app';
export const CSP = {
  nonce: REACT_SELECT_NONCE
};
export default function Document() {
  const originUrl = typeof window !== 'undefined' && window.location.origin || '';
  return <Html data-sentry-element="Html" data-sentry-component="Document" data-sentry-source-file="_document.tsx">
      <Head nonce={CSP.nonce} data-sentry-element="Head" data-sentry-source-file="_document.tsx"></Head>
      <body>
        <Main data-sentry-element="Main" data-sentry-source-file="_document.tsx" />
        <NextScript nonce={CSP.nonce} data-sentry-element="NextScript" data-sentry-source-file="_document.tsx" />
        <Script src={`${originUrl}/env.js`} strategy="beforeInteractive" data-sentry-element="Script" data-sentry-source-file="_document.tsx" />
      </body>
    </Html>;
}