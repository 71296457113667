import { Toaster, ToasterProvider } from '@latitude/toast';
import { TooltipProvider, TooltipProviderProps } from '@latitude/tooltip';
import { ReactNode } from 'react';

/**
 * Provides a context for managing toasts in the application.
 *
 * @component
 * @example
 * ```tsx
 * <ToasterProvider>
 *   <App />
 * </ToasterProvider>
 * ```
 *
 * @param children - The child components to be wrapped by the provider.
 * @returns The provider component.
 */
export const ToasterAndTooltipProvider = ({
  children,
  withToaster = false,
  tooltipProps = {},
}: {
  children: ReactNode;
  withToaster?: boolean;
  tooltipProps?: Omit<TooltipProviderProps, 'children'>;
}) => {
  return (
    <TooltipProvider {...tooltipProps}>
      <ToasterProvider>
        {children}
        {withToaster && <Toaster />}
      </ToasterProvider>
    </TooltipProvider>
  );
};
