import * as Sentry from '@sentry/browser';

/**
 * @deprecated use the one exported from `'@anchorage/sentry';`
 *
 * setErrorUser attaches userId to an event
 * @param userId User ID
 */
const setErrorUser = (userId?: string): void => {
  if (!userId) {
    return;
  }

  const scope = Sentry.getCurrentScope();
  const currentUser = scope.getUser();

  // Already set the scope with this user
  if (currentUser && currentUser.id === userId) {
    return;
  }
  scope.setUser({ id: userId });
};

export default setErrorUser;
