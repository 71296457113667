import type { DefaultOptions, NextLink, Operation } from '@apollo/client';
import { ApolloClient, ApolloLink, ApolloProvider, InMemoryCache } from '@apollo/client';
import type { ErrorHandler } from '@apollo/client/link/error';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { relayStylePagination } from '@apollo/client/utilities';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import fragmentMatcher from 'generated/fragment-matcher';
import { visit } from 'graphql';
import * as React from 'react';
import { reportError } from '@anchorage/common/dist/utils/errors';
import type { ClientError } from '@anchorage/common/dist/utils/errors/WrappedError';
import { NODE_ENV } from '@anchorage/frontoffice/constants/app';
import { handleNetworkError } from 'utils/apollo-client/api';
import { EventStreamLink } from './handle-server-sent-events';
const {
  PROD
} = NODE_ENV;
type Props = {
  children: React.ReactNode;
};
const errorHandler: ErrorHandler = ({
  graphQLErrors,
  networkError
}) => {
  if (graphQLErrors) {
    graphQLErrors.map(error => reportError(error as ClientError));
  }
  if (networkError) {
    // Typescript requires the first check because
    // the types Error and ServerError do not have a bodyText property
    if ('bodyText' in networkError && networkError.bodyText) {
      try {
        JSON.parse(networkError.bodyText);
      } catch (e) {
        networkError.message = 'Network Error: Please try again';
      }
    }
    handleNetworkError({
      error: networkError
    });
  }
};
function createCache() {
  return new InMemoryCache({
    possibleTypes: fragmentMatcher.possibleTypes,
    typePolicies: {
      Vault: {
        keyFields: ['uniqueID'],
        fields: {
          wallets: relayStylePagination()
        }
      }
    }
  });
}
const graphqlErrorHandler = onError(errorHandler);
const retryLink = new RetryLink({
  delay: {
    // Number of ms to wait before the first retry
    initial: 3000,
    // The max number of ms to wait for any retry
    max: Infinity,
    // Randomize delays between attempts
    jitter: true
  },
  attempts: {
    // Try up to `max` times before giving up
    max: 15,
    // Only retry network connectivity issues
    retryIf: error => {
      return error && error.toString().includes('Failed to fetch');
    }
  }
});
const urlEditingLink = new ApolloLink((operation: Operation, forward: NextLink) => {
  const getOperationName = (operation: Operation) => {
    if (operation.operationName) {
      return operation.operationName;
    }
    return 'UnnamedOperation';
  };

  // Modify the URI directly within the custom link
  operation.setContext(() => {
    const operationName = getOperationName(operation);
    const newUri = `/graphql?queryName=${operationName}`;
    return {
      uri: newUri
    };
  });
  return forward(operation);
});

// Disable @defer and @stream directives when the query context requests it by removing these directives from the query
const disableDeferLink = new ApolloLink((operation, forward) => {
  if (operation.getContext().disableIncrementalDelivery) {
    operation.query = visit(operation.query, {
      Directive(node) {
        if (node.name.value === 'defer' || node.name.value === 'stream') {
          return null;
        }
      }
    });
  }
  return forward(operation);
});

// @ts-ignore
const uploadLink = new createUploadLink({
  uri: '/graphql',
  // Pass along cookies
  credentials: 'same-origin'
});

// If this query contains @defer or @stream, use EventStreamLink to handle Server Sent Events (SSE)
const makeRequestLink = ApolloLink.split(operation => {
  let isEventStream = false;
  visit(operation.query, {
    Directive(node) {
      if (node.name.value === 'defer' || node.name.value === 'stream') {
        isEventStream = true;
      }
    }
  });
  return isEventStream;
}, EventStreamLink, uploadLink // Default link for non-SSE queries
);
const link = ApolloLink.from([retryLink, graphqlErrorHandler, urlEditingLink, disableDeferLink, makeRequestLink]);
const connectToDevTools = process.env.NODE_ENV !== PROD;

// https://www.apollographql.com/docs/react/api/core/ApolloClient/#options
// Note: The useQuery hook uses Apollo Client's watchQuery function.
// To set defaultOptions when using the useQuery hook, make sure to set
// them under the defaultOptions.watchQuery property.
const defaultOptions: DefaultOptions = {
  watchQuery: {
    // TODO (@volkan-anchor) [08/18/2021]: Experiment with no-cache
    // we should use cache very intentionally
    // Cypress GUI in dev mode crashes with cache: 'network-only' - graphql requests loop
    fetchPolicy: typeof window !== 'undefined' && window.Cypress ? 'no-cache' : 'network-only'
  }
};
const client = new ApolloClient({
  link,
  cache: createCache(),
  connectToDevTools,
  defaultOptions
});
class ApolloClientProvider extends React.Component<Props> {
  render() {
    const {
      children
    } = this.props;
    return <ApolloProvider client={client} data-sentry-element="ApolloProvider" data-sentry-component="ApolloClientProvider" data-sentry-source-file="index.tsx">{children}</ApolloProvider>;
  }
}
export default ApolloClientProvider;