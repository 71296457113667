import { SentryEnvironments } from '@anchorage/sentry';

// The data-testid to use for any error boundaries
export const ERROR_FOUND_TESTID = 'error-found';

// Returns true if running in production environment
export const isProduction =
  typeof window !== 'undefined' &&
  window.location.hostname === 'anchoragelogin.com';

// Contact email for API questions
export const API_CONTACT_EMAIL = 'api@anchorage.com';

export const REACT_SELECT_NONCE =
  'OWRjNmFhNDQtZGQxNy00ZDEwLTgxMzItMjU5Y2QxYzVlMTc2';

// Environments
export const NODE_ENV = {
  PROD: 'production',
  DEV: 'development',
};

export function getSplitEnvOptions() {
  const defaultApiKey = 'qdko9v1bakt26peg3rpce8s2orlc3l30i4ab';

  if (typeof window !== 'undefined') {
    switch (window.env?.SENTRY_ENVIRONMENT) {
      case SentryEnvironments.STAGING:
        return {
          apiKey: 'r9nhat14obp113p6fgha37ttdjhpsdn9gm0o',
        };
      case SentryEnvironments.PRODUCTION:
        return {
          apiKey: 'pplvshnc6angjvl71bprurs5gba77is6ghs1',
        };
      default:
        return {
          apiKey: defaultApiKey,
        };
    }
  } else {
    return {
      apiKey: defaultApiKey,
    };
  }
}

// Mixpanel
const getMixpanelOptions = () => {
  const defaultToken = 'ab7b3cfe55ce788636434e53671f9cee';

  if (typeof window !== 'undefined') {
    switch (window.env?.SENTRY_ENVIRONMENT) {
      case SentryEnvironments.STAGING:
        return {
          token: '0750790e29a5f19377d60b784ccc952f',
        };
      case SentryEnvironments.PRODUCTION:
        return {
          token: 'df77b736dd9614136248ae018d3fad60',
        };
      default:
        return {
          token: defaultToken,
        };
    }
  } else {
    return {
      token: defaultToken,
    };
  }
};

export const MIXPANEL_OPTIONS = {
  debug: false,
  ...getMixpanelOptions(),
};

export enum ORG_OPERATOR_TYPES {
  SELF = 'SELF',
  ANCHORAGE = 'ANCHORAGE',
}
