import cn from 'classnames';

const PriorityIcon = (props: SVGProps): JSX.Element => {
  const { title = 'Priority', className, ...restProps } = props;

  return (
    <svg
      className={cn('fill-current', className)}
      width="10"
      height="14"
      viewBox="0 0 10 14"
      xmlns="http://www.w3.org/2000/svg"
      {...restProps}
    >
      <title>{title}</title>
      <path
        id="Vector"
        d="M5 13.6666L0.333336 8.99992L1.26667 8.04992L4.33334 11.1166V6.33325H5.66667V11.1166L8.73334 8.06658L9.66667 8.99992L5 13.6666ZM4.33334 4.99992V2.99992H5.66667V4.99992H4.33334ZM4.33334 1.66659V0.333252H5.66667V1.66659H4.33334Z"
      />
    </svg>
  );
};

export { PriorityIcon };
