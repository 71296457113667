import { animated } from '@react-spring/web';
import cn from 'classnames';
import React from 'react';

import { useAnimateHeight, useSpacing } from '@anchorage/hooks';

import styles from '../../index.css';

import { BannerWrapperProps } from '../../_types';

import { BannerContext } from '../../useBannerContext';

type Props = BannerWrapperProps;

const BannerWrapper: React.FC<Props> = ({
  className = '',
  children,
  isVisible = true,
  type = 'info',
  onClose,
  spacing,
  ...restProps
}) => {
  const spacingStyles = useSpacing(spacing);
  const [hasActions, setHasActions] = React.useState(false);
  const [bannerRef, transitions] = useAnimateHeight(isVisible);

  const classes = cn({
    [className]: !!className,
    [styles.root]: true,
    [styles[type]]: true,
  });

  return transitions(
    (animationStyles, item) =>
      item && (
        <BannerContext.Provider
          value={{ setHasActions, hasActions, type, onClose }}
        >
          <animated.div style={animationStyles}>
            <div
              ref={bannerRef}
              style={spacingStyles}
              className={classes}
              data-testid="banner-wrapper"
              {...restProps}
            >
              {children}
            </div>
          </animated.div>
        </BannerContext.Provider>
      ),
  );
};

export default BannerWrapper;
