import * as ToastPrimitives from '@radix-ui/react-toast';
import cn from 'classnames';
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';

/**
 * Represents the title component of a toast.
 *
 * @component
 * @example
 * ```tsx
 * <ToastTitle className="custom-title" />
 * ```
 */
export const ToastTitle = forwardRef<
  ElementRef<typeof ToastPrimitives.Title>,
  ComponentPropsWithoutRef<typeof ToastPrimitives.Title>
>(({ className, ...props }, ref) => {
  return (
    <ToastPrimitives.Title
      ref={ref}
      className={cn(
        [
          'break-all',
          'break-words',
          'line-clamp-2',
          'text-ellipsis',
          'overflow-hidden',
          'whitespace-normal',
          'font-ref-web-body-medium-font-family',
          'font-ref-body-bold-font-weight',
          'text-font-ref-body-medium-font-size',
          'tracking-font-ref-web-body-medium-letter-spacing',
          'leading-ref-web-body-medium-line-height',
        ],
        className,
      )}
      {...props}
    />
  );
});
ToastTitle.displayName = ToastPrimitives.Title.displayName;
