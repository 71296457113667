import { useReducer } from 'react';

import { ToastProps, ToastsState } from '../types';

const TOAST_LIMIT = 4;
const TOAST_REMOVE_DELAY = 5000;

/**
 * Enum representing the action types for toast operations.
 */
export enum ACTION_TYPES {
  ADD_TOAST = 'ADD_TOAST',
  DISMISS_TOAST = 'DISMISS_TOAST',
  REMOVE_TOAST = 'REMOVE_TOAST',
}

/**
 * Represents the type of action for the toast reducer.
 */
type ActionType = typeof ACTION_TYPES;

/**
 * Represents an action that can be dispatched to the toast reducer.
 */
export type Action =
  | {
      type: ActionType['ADD_TOAST'];
      toast: ToastProps;
    }
  | {
      type: ActionType['DISMISS_TOAST'];
      toastId?: ToastProps['id'];
      dispatch: (value: Action) => void;
    }
  | {
      type: ActionType['REMOVE_TOAST'];
      toastId?: ToastProps['id'];
    };

const toastTimeouts = new Map<string, ReturnType<typeof setTimeout>>();

/**
 * Adds a toast to the remove queue and schedules its removal after a delay.
 * If the toast is already in the remove queue, it will not be added again.
 *
 * @param toastId - The ID of the toast to be added to the remove queue.
 * @param dispatch - The dispatch function from the useToastReducer hook.
 */
const addToRemoveQueue = (
  toastId: string,
  dispatch: (value: Action) => void,
) => {
  if (toastTimeouts.has(toastId)) {
    return;
  }

  const timeout = setTimeout(() => {
    toastTimeouts.delete(toastId);
    dispatch({
      type: ACTION_TYPES.REMOVE_TOAST,
      toastId: toastId,
    });
  }, TOAST_REMOVE_DELAY);

  toastTimeouts.set(toastId, timeout);
};

/**
 * Reducer function for managing the state of toasts.
 *
 * @param state - The current state of toasts.
 * @param action - The action to be performed on the state.
 * @returns The new state of toasts after applying the action.
 */
const reducer = (state: ToastsState, action: Action): ToastsState => {
  switch (action.type) {
    case ACTION_TYPES.ADD_TOAST:
      return [action.toast, ...state].slice(0, TOAST_LIMIT);

    case ACTION_TYPES.DISMISS_TOAST: {
      const { toastId, dispatch } = action;

      if (toastId) {
        addToRemoveQueue(toastId, dispatch);
      } else {
        state.forEach((toast) => {
          addToRemoveQueue(toast.id, dispatch);
        });
      }

      return state.map((toast) =>
        toast.id === toastId || toastId === undefined
          ? {
              ...toast,
              open: false,
            }
          : toast,
      );
    }
    case ACTION_TYPES.REMOVE_TOAST: {
      if (action.toastId === undefined) {
        return [];
      }
      return state.filter((toast) => toast.id !== action.toastId);
    }
  }
};

/**
 * Custom hook that creates a toast reducer and returns the toasts state and dispatch function.
 *
 * @param initialState - The initial state for the toasts.
 * @returns An object containing the toasts state and dispatch function.
 */
export const useToastReducer = (initialState: ToastsState) => {
  const [toasts, dispatch] = useReducer(reducer, initialState);

  return { toasts, dispatch };
};
