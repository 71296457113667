import * as React from 'react';

// Utils
import { useDeepCompare } from '@anchorage/hooks';

import { generateSharedPickerSettings } from './_utils';

// Types
import type { DateTimePickerProps, UseDateTimePickerProps } from './_types';

// Constants
import { DEFAULT_NAV_PROPS } from './_constants';

type AdditionalProps = {
  selected: Date | null;
};

const useDateTimePicker = ({
  initialDate = null,
  pickerProps = {},
  headerProps,
  inputProps = {},
  timePickerProps,
}: UseDateTimePickerProps): [
  AdditionalProps & DateTimePickerProps,
  DateTimePickerProps,
] => {
  const [selected, setSelected] = React.useState<Date | null>(initialDate);
  const timePickerRef = React.useRef<HTMLInputElement>(null);

  const { onChange, ...restPickerProps } = pickerProps;

  const handleOnChange = React.useCallback(
    (date: Date | null) => {
      setSelected(date);
      timePickerRef.current?.focus();
    },
    useDeepCompare([timePickerRef, setSelected]),
  );

  React.useEffect(
    () => {
      onChange?.(selected);
    },
    useDeepCompare([onChange, selected]),
  );

  const baseProps = {
    pickerProps: {
      onChange: handleOnChange,
      selected,
      shouldCloseOnSelect: !pickerProps?.showTimeInput,
      ...generateSharedPickerSettings({
        showTimeInput: Boolean(pickerProps?.showTimeInput),
      }),
      ...restPickerProps,
    },
    headerProps: {
      ...DEFAULT_NAV_PROPS,
      ...headerProps,
    },
    inputProps: {
      setSelected,
      ...inputProps,
    },
    timePickerProps: {
      setSelected,
      selected,
      ref: timePickerRef,
      ...timePickerProps,
    },
  };

  return [{ ...baseProps, selected }, baseProps];
};

export default useDateTimePicker;
