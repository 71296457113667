import * as React from 'react';

// Hooks
import { useDebounce } from '@anchorage/hooks';

// Components
import InputV2 from '../InputV2';
// Types
import type { InputProps } from '../InputV2';

export const INPUT_DEBOUNCE_TIME = 500;

export interface InputDebounceProps
  extends Omit<InputProps, 'value' | 'onChange'> {
  value?: string;
  onChange?: (updateValue: string) => void;
  debounceTime?: number;
}

const InputWithDebounce = ({
  onChange,
  placeholder,
  label,
  value: propValue = '',
  debounceTime = INPUT_DEBOUNCE_TIME,
  ...restProps
}: InputDebounceProps) => {
  const [value, setValue] = React.useState(propValue);
  const debouncedValue = useDebounce(value, debounceTime);

  React.useEffect(() => {
    onChange?.(debouncedValue);
  }, [debouncedValue]);

  React.useEffect(() => {
    if (propValue !== debouncedValue) {
      setValue(propValue);
    }
  }, [propValue]);

  return (
    <InputV2
      label={label}
      onChange={({ currentTarget }) => setValue(currentTarget.value)}
      value={value}
      placeholder={placeholder}
      data-testid="input-debounce"
      {...restProps}
    />
  );
};

export default InputWithDebounce;
